function reactArray(compenentId){
    let arr = ["FreetextSearch", "SorIdController", "EntityTypeNameSearch", "TodataSearch", "SpecialityListSearch", "SorTypeSearch", "EanLocationCodeSearch", "ShakIdSearch",
            "PostalAddressSearch", "RegionNameSearch", "PostalMunicipalityNameSearch", "PostalCodeRangeStart", "PostalCodeRangeEnd", "ValidFromSearch", "EanRegionNameSearch", 
            "ModifiedDateSearch", "CodeSystemSearch", "CodeSystemSupplierName", "CommunicationSupplierSearch", "EdiFactSearch", "InstitutionOwnerEntityNameSearch",  
                 ]
    const index = arr.indexOf(compenentId)
    if(index > -1 ){
        arr.splice(index, 1)
    }
    return arr
}
export default reactArray 