import React, { useState } from 'react'
import BuildMenu from './BuildMenu'
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import BuildTree from './BuildTree';

/*This component enables the user to build their own tree structure from a list of defined filters.*/
function DynamicTree(props) {

    const [showLvl1Children, setShowLvl1Children] = useState([]);
    const [showLvl2Children, setShowLvl2Children] = useState([]);
    const [showLvl3Children, setShowLvl3Children] = useState([]);
    const [showLvl4Children, setShowLvl4Children] = useState([]);
    const [showOeChildren, setShowOeChildren] = useState([]);
    const [showTreeLines, setShowTreeLines] = useState(false);
    const [filters, setFilters] = useState([{
        name: "Institutionsejer type",
        category: "level1",
        bucket: "InstitutionOwnerEntityTypeName"
    },

    {
        name: "Sundhedsinstitutions type",
        category: "pool",
        bucket: "SorType"
    },

    {
        name: "Postnummer",
        category: "pool",
        bucket: "PostalPostCodeId"
    },
    {
        name: "Kommune",
        category: "level2",
        bucket: "PostalMunicipalityName"
    },
    {
        name: "Region",
        category: "pool",
        bucket: "PostalAddressRegionName"
    },
    {
        name: "Speciale",
        category: "pool",
        bucket: "SpecialityName"
    },
    {
        name: "Geografisk lokalitet",
        category: "pool",
        bucket: "GeographicalLocalisationName"
    }

    ]);


    const handleFilterChange = (filters) => {
        setFilters(filters);
    }

    /*This function handles clicks on the first filter entities. */
    const handleLvl1Click = (key) => {
        let array = Array.from(showLvl1Children);
        let index = array.indexOf(key);
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(key);
        }
        setShowLvl1Children(array);
    }

    /*This function handles clicks on the second filter entities. */
    const handleLvl2Click = (key) => {
        let array = Array.from(showLvl2Children);
        let index = array.indexOf(key);
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(key);
        }
        setShowLvl2Children(array);
    }

    /*This function handles clicks on the third filter entities. */
    const handleLvl3Click = (key) => {
        let array = Array.from(showLvl3Children);
        let index = array.indexOf(key);
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(key);
        }
        setShowLvl3Children(array);

    }

    /*This function handles clicks on the fourth filter entities. */
    const handleLvl4Click = (key) => {
        let array = Array.from(showLvl4Children);
        let index = array.indexOf(key);
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(key);
        }
        setShowLvl4Children(array);

    }

    /*Handle clicks on entities on level > 2. When clicked the state is updated and the entities which are children of the given entity is displayed.*/
    const handleOeEntityClick = (_id) => {
        let array = Array.from(showOeChildren);
        let index = array.indexOf(_id);
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(_id);
        }
        setShowOeChildren(array);
    }

    /*Handle hover events and displays zebra lines over the displayed fields from TreeFields.jsx*/
    const handleTreeFieldsEvent = (e) => {
        setShowTreeLines(e.type === "mouseenter" ? true : false);
    }


    let level1Bucket = null
    let level2Bucket = null
    let level3Bucket = null
    let level4Bucket = null

    /*Filters which filters is selected by the user.*/
    const level1 = filters.filter(filter => filter.category === "level1")
    if (level1.length > 0) {
        level1Bucket = level1[0].bucket
    }
    const level2 = filters.filter(filter => filter.category === "level2")
    if (level2.length > 0) {
        level2Bucket = level2[0].bucket
    }
    const level3 = filters.filter(filter => filter.category === "level3")
    if (level3.length > 0) {
        level3Bucket = level3[0].bucket
    }
    const level4 = filters.filter(filter => filter.category === "level4")
    if (level4.length > 0) {
        level4Bucket = level4[0].bucket
    }

    return (
        <React.Fragment>
            <BuildMenu filters={filters} handleFilterChange={handleFilterChange} />
            <div className="col-12 mb-3">
                <ReactiveComponent
                    componentId="DynamicTreeResult"
                    dataField="EntityName"
                    selectedOrNot={props.selectedOrNot}
                    handleClick={props.handleClick}
                    showLvl1Children={showLvl1Children}
                    handleLvl1Click={handleLvl1Click}
                    showLvl2Children={showLvl2Children}
                    handleLvl2Click={handleLvl2Click}
                    showLvl3Children={showLvl3Children}
                    handleLvl3Click={handleLvl3Click}
                    showLvl4Children={showLvl4Children}
                    handleLvl4Click={handleLvl4Click}
                    handleOeEntityClick={handleOeEntityClick}
                    handleTreeFieldsEvent={(e) => handleTreeFieldsEvent(e)}
                    showOeChildren={showOeChildren}
                    showFields={props.showFields}
                    showTreeLines={showTreeLines}
                    level1Bucket={level1Bucket}
                    level2Bucket={level2Bucket}
                    level3Bucket={level3Bucket}
                    level4Bucket={level4Bucket}
                    defaultQuery={() => ({
                        "query": {
                            "match_all": {

                            },

                        },
                        "size": 0,
                        "aggs": {
                            "group_by_level1Bucket": {
                                "terms": {
                                    "field": level1Bucket + ".keyword",
                                    "missing": level1Bucket === null ? "Alle" : "N/A",
                                    "size": 40
                                },
                                "aggs": {
                                    "group_by_level2Bucket": {
                                        "terms": {
                                            "field": level2Bucket + ".keyword",
                                            "missing": level2Bucket === null ? "Alle" : "N/A"
                                        },
                                        "aggs": {
                                            "group_by_level3Bucket": {
                                                "terms": {
                                                    "field": level3Bucket + ".keyword",
                                                    "missing": level3Bucket === null ? "Alle" : "N/A"
                                                },
                                                "aggs": {
                                                    "group_by_level4Bucket": {
                                                        "terms": {
                                                            "field": level4Bucket + ".keyword",
                                                            "missing": level4Bucket === null ? "Alle" : "N/A"
                                                        }
                                                    },
                                                },
                                            },
                                        }
                                    }
                                }
                            }
                        }
                    })
                    }
                    react={{
                        and: ["TodataSearch", "EntityTypeNameSearch", "InstitutionOwnerEntityNameSearch"]
                    }}

                    render={({ aggregations, setQuery }) => (
                        <BuildTree
                            selectedOrNot={props.selectedOrNot}
                            handleClick={props.handleClick}
                            showLvl1Children={showLvl1Children}
                            handleLvl1Click={handleLvl1Click}
                            showLvl2Children={showLvl2Children}
                            handleLvl2Click={handleLvl2Click}
                            showLvl3Children={showLvl3Children}
                            handleLvl3Click={handleLvl3Click}
                            showLvl4Children={showLvl4Children}
                            handleLvl4Click={handleLvl4Click}
                            handleOeEntityClick={handleOeEntityClick}
                            handleTreeFieldsEvent={(e) => handleTreeFieldsEvent(e)}
                            showOeChildren={showOeChildren}
                            setShowOeChildren={setShowOeChildren}
                            showFields={props.showFields}
                            showTreeLines={showTreeLines}
                            setShowTreeLines={setShowTreeLines}
                            level1Bucket={level1Bucket}
                            level2Bucket={level2Bucket}
                            level3Bucket={level3Bucket}
                            level4Bucket={level4Bucket}
                            aggregations={aggregations}
                            setQuery={setQuery}
                        />
                    )}

                />
            </div>
        </React.Fragment>
    )
}
export default DynamicTree