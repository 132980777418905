import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from "react-router-dom"

library.add(faBars)

/* Navbar is the component that renders the navigation bar. Navigation between components is done with react-router-dom. */

function Navbar(props) {

    return (
        <nav className="navbar navbar-expand-lg col-12 p-0">

            <div className="col-3 col-md-4 col-xl-3 text-center navbar-text--white">Filtre</div>

            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <FontAwesomeIcon icon="bars" />
            </button>

            <div id="navbarSupportedContent" className="collapse navbar-collapse">
                <ul className="navbar-nav col-12 col-xl-8">
                    <li className="nav-item col-12 col-lg ">
                        <NavLink end className={(navData) => navData.isActive ? "nav-link is-active" : "nav-link"} to={{ pathname: "/", search: props.SearchParams }}>Søgning</NavLink>
                    </li>
                    <li className="nav-item col-12 col-lg">
                        <NavLink className={(navData) => (navData.isActive ? "nav-link nav-link--large is-active" : "nav-link nav-link--large")} to={{ pathname: "/tree", search: props.SearchParams }}>Organisatorisk træ</NavLink>
                    </li>
                    <li className="nav-item col-12 col-lg">
                        <NavLink className={(navData) => (navData.isActive ? "nav-link is-active" : "nav-link")} to={{ pathname: "/map", search: props.SearchParams }}>Kort</NavLink>
                    </li>
                    <li className="nav-item col-12 col-lg">
                        <NavLink className={(navData) => (navData.isActive ? "nav-link is-active" : "nav-link")} to="/support">Vejledning</NavLink>
                    </li>
                </ul>
                {props.class !== "supportpage" &&
                    <div className="col-3 col-md-2 col-xl-4 text-center navbar-text--white navbar__details-header">Information om SOR enhed</div>
                }
            </div>

        </nav>
    )
}

export default Navbar

/*
    Byg selv er deaktiveret, for at aktivere, indsæt nedenstående mellem Kort og Vejledning

                    <li className="nav-item col-12 col-lg">
                        <NavLink className={(navData) => (navData.isActive ? "nav-link is-active" : "nav-link")} to="/build-tree">Byg selv</NavLink>
                    </li>

 
  */