import React from 'react';

function TreeFields(props) {


    const handleHtmlMarkup = (data) => {
        return { __html: data }
    }

    /*Handle hover events and displays zebra lines over the displayed fields from TreeFields.jsx*/
    const handleTreeFieldsEvent = (e) => {
        props.setShowTreeLines(e.type === "mouseenter" ? true : false);
    }


    if (props.EntityTypeName) {
        return (
            <div className="search-result--tree__fields">
                <div className="row" onMouseEnter={(e) => handleTreeFieldsEvent(e)} onMouseLeave={(e) => handleTreeFieldsEvent(e)}>

                    {props.showFields.indexOf("CheckEntityTypeName") !== -1 &&
                        <div title="Enhedstype" className="search-result--tree__entitytypename">

                            {props.EntityTypeName}
                        </div>
                    }

                    {props.showFields.indexOf("CheckAddress") !== -1 &&
                        <React.Fragment>
                            {props.SorType === "IE" &&
                                <div title="Adresse" className="search-result--tree__postaladdress">
                                    <span dangerouslySetInnerHTML={handleHtmlMarkup(props.PostalAddress)} />.&nbsp;
                                    <div>{props.PostalPostCodeId} {props.PostalDistrictName}</div>
                                </div>
                            }
                            {props.SorType !== "IE" &&
                                <div title="Adresse" className="search-result--tree__postaladdress">
                                    <React.Fragment>
                                        <span dangerouslySetInnerHTML={handleHtmlMarkup(props.VisitingAddress)} />.&nbsp;
                                        <span>
                                            {props.VisitingAddressPostCodeId} {props.VisitingAddressDistrictName}
                                        </span>
                                    </React.Fragment>
                                </div>
                            }
                        </React.Fragment>
                    }

                    {props.showFields.indexOf("CheckSpecialityName") !== -1 && props.SpecialityName &&
                        <div title="Specialer" className="search-result--tree__specialityname">
                            {props.SpecialityName.join(", ")}
                        </div>
                    }
                    {props.showFields.indexOf("CheckInstitutionOwnerCvrNumberId") !== -1 &&
                        <div title="CVR nummer" className="search-result--tree__cvrid">
                            <span dangerouslySetInnerHTML={handleHtmlMarkup(props.InstitutionOwnerCvrNumberId)} />
                        </div>
                    }
                    {props.showFields.indexOf("CheckSorId") !== -1 &&
                        <div title="SOR kode" className="search-result--tree__sordid">
                            <span dangerouslySetInnerHTML={handleHtmlMarkup(props.SorId)} />
                        </div>
                    }
                    {props.showFields.indexOf("CheckShakId") !== -1 &&
                        <div title="SHAK kode" className="search-result--tree__shakid">
                            <span dangerouslySetInnerHTML={handleHtmlMarkup(props.ShakId)} />
                        </div>
                    }
                    {props.showFields.indexOf("CheckEanLocationCode") !== -1 &&
                        <div title="Lokationsnummer" className="search-result--tree__eanlocationcode">
                            <span dangerouslySetInnerHTML={handleHtmlMarkup(props.EanLocationCode)} />
                        </div>
                    }
                    {props.showFields.indexOf("CheckProviderId") !== -1 &&
                        <div title="Ydernummer" className="search-result--tree__providerid">
                            <span dangerouslySetInnerHTML={handleHtmlMarkup(props.ProviderId)} />
                        </div>

                    }
                    {props.showFields.indexOf("CheckPharmacyId") !== -1 &&
                        <div title="Apoteksnummer" className="search-result--tree__pharmacyId">
                            <span dangerouslySetInnerHTML={handleHtmlMarkup(props.PharmacyId)} />
                        </div>
                    }

                </div>
            </div>
        )
    }
    return null;
}
export default TreeFields