import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Support from './Support/Support';
import './App.css';
import SearchPage from './SearchPage';
import './index.scss';
import CacheBuster from 'react-cache-buster';

class App extends Component {
    render() {
        console.log("SOR Browser 1.5.2");
        return (
            <CacheBuster currentVersion="1.5.2" isEnabled={true} isVerboseMode={false}>
                <BrowserRouter>
                    <Routes>
                        <Route exact path="/"            element={<SearchPage/>}/>
                        <Route exact path="/map"         element={<SearchPage />} />
                        <Route exact path="/tree"        element={<SearchPage />} />
                        <Route exact path="/build-tree"  element={<SearchPage />} />
                        <Route exact path="/support"     element={<Support />} />
                    </Routes>
                </BrowserRouter>
            </CacheBuster>
        )

        /* 
        * Denne side vises n�r SOR ikke er tilg�ngelig
        *
         return (
               <div>
                   <header className="row search-header">
                       <div className="logo-container"><img className="logo" src="logo.png" alt="logo"></img></div>
                       <h1>SOR - Sundhedsv�senets Organisationsregister</h1>
                   </header>
                   <br/><br/>
                   <div style={{ textAlign : 'center' }}>
                       <h3>SOR Browser er ikke tilg�ngelig i �jeblikket.</h3>
                       <br/><br/>
                       <h3>Der arbejdes p� at l�se problemet.</h3>
                   </div>
               </div>
           )
           */

    }
}
export default App;


