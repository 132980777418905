import React from 'react'

function BuildMenu(props) {

    const onDragStart = (ev, id) => {
        ev.dataTransfer.setData("id", id)
    }

    const onDragOver = (ev) => {
        ev.preventDefault();
    }

    const onDrop = (ev, cat) => {
        let id = ev.dataTransfer.getData("id");

        let filters = props.filters.filter((filter) => {
            if (filter.name === id) {
                filter.category = cat
            }
            return filter
        })

        props.handleFilterChange(filters)
    }

    const filters = {
        pool: [],
        level1: [],
        level2: [],
        level3: [],
        level4: []
    }

    props.filters.forEach((filter) => {

        filters[filter.category].push(<div
            key={filter.name}
            onDragStart={(e) => onDragStart(e, filter.name)}
            draggable
            className="btn btn-sm draggable"
        >
            {filter.name}
        </div>)
    })

    return (
        <React.Fragment>
            <div>
                <button className="btn btn-sm build-menu__toggle ml-3" type="button" data-toggle="collapse" data-target="#build-menu" aria-expanded="false" aria-controls="build-menu">
                    Byg selv
                </button>
            </div>
            <div className="collapse col-12 build-menu show" id="build-menu">
                <div className="card card-body mt-3">
                    <h3>Byg hieraki</h3>
                    <div className="mb-3">Opstil dit eget hierarki ved at trække parametre til højre kolonne</div>
                    <div className="row">
                        <div className="col-6">
                            <div className="pool p-2 mb-3"
                                onDragOver={(e) => onDragOver(e)}
                                onDrop={(e) => { onDrop(e, "pool") }}>
                                <span className="task-header">Filtre</span>
                                {filters.pool}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="droppable p-2 mb-3"
                                onDragOver={(e) => onDragOver(e)}
                                onDrop={(e) => onDrop(e, "level1")}>
                                <span className="task-header">Niveau 1</span>
                                {filters.level1}
                            </div>
                            <div className="droppable p-2 mb-3"
                                onDragOver={(e) => onDragOver(e)}
                                onDrop={(e) => onDrop(e, "level2")}>
                                <span className="task-header">Niveau 2</span>
                                {filters.level2}
                            </div>
                            <div className="droppable p-2 mb-3"
                                onDragOver={(e) => onDragOver(e)}
                                onDrop={(e) => onDrop(e, "level3")}>
                                <span className="task-header">Niveau 3</span>
                                {filters.level3}
                            </div>
                            <div className="droppable p-2"
                                onDragOver={(e) => onDragOver(e)}
                                onDrop={(e) => onDrop(e, "level4")}>
                                <span className="task-header">Niveau 4</span>
                                {filters.level4}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
}
export default BuildMenu