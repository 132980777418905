import React from 'react'
import OeEntity from './OeEntity'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretDown, faCircle } from '@fortawesome/free-solid-svg-icons'

library.add(faCaretDown, faCaretRight, faCircle)
/*Builds the dynamic tree calls is subsequently OeEntity.jsx to render the entities under a given filter.*/
function BuildTree(props) {

    if (props.aggregations) {
        return (
            <ul className={props.showTreeLines ? "search-results--tree search-results--tree-lines" : "search-results--tree"}>
                {props.aggregations.group_by_level1Bucket.buckets.map((result) =>
                    <li key={result.key} className="search-result--tree__circle-1">
                        <a href={"#level2bucket" + result.key}
                            className={props.showLvl1Children.indexOf(result.key) === -1 ? "collapsed" : ""}
                            aria-expanded="false" aria-controls={result.key.replace(/\s+/g, '')}
                            onClick={() => props.handleLvl1Click(result.key)}>

                            <span className="circle"><FontAwesomeIcon icon="circle" /></span>
                            <span className="caret-right"><FontAwesomeIcon icon="caret-right" /></span>
                            <span className="caret-down"><FontAwesomeIcon icon="caret-down" /></span>
                            {result.key} ({result.doc_count})
                        </a>

                        <ul className={props.showLvl1Children.indexOf(result.key) === -1 ? "collapse" : "collapse show"} id={"level2bucket" + result.key}>
                            {result.group_by_level2Bucket && props.showLvl1Children.indexOf(result.key) !== -1 &&
                                result.group_by_level2Bucket.buckets.map((result2) =>
                                    <li key={result2.key} className="search-result--tree__circle-2">
                                        <a href={"#level3bucket" + result2.key}
                                            className={props.showLvl2Children.indexOf(result2.key) === -1 ? "collapsed" : ""}
                                            aria-expanded="false" aria-controls={result2.key.replace(/\s+/g, '')}
                                            onClick={() => props.handleLvl2Click(result2.key)}>

                                            <span className="circle"><FontAwesomeIcon icon="circle" /><FontAwesomeIcon icon="circle" /></span>

                                            <span className="caret-right"><FontAwesomeIcon icon="caret-right" /></span>
                                            <span className="caret-down"><FontAwesomeIcon icon="caret-down" /></span>
                                            {result2.key} ({result2.doc_count})
                                        </a>

                                        <ul className={props.showLvl2Children.indexOf(result2.key) === -1 ? "collapse" : "collapse show"} id={"level3bucket" + result2.key}>
                                            {result2.group_by_level3Bucket && props.showLvl2Children.indexOf(result2.key) !== -1 &&
                                                result2.group_by_level3Bucket.buckets.map((result3) =>
                                                    <li key={result3.key} className="search-result--tree__circle-3">
                                                        <a href={"#level4bucket" + result3.key}
                                                            className={props.showLvl3Children.indexOf(result3.key) === -1 ? "collapsed" : ""}
                                                            aria-expanded="false" aria-controls={result3.key.replace(/\s+/g, '')}
                                                            onClick={() => props.handleLvl3Click(result3.key)}>

                                                            <span className="circle"><FontAwesomeIcon icon="circle" /><FontAwesomeIcon icon="circle" /><FontAwesomeIcon icon="circle" /></span>
                                                            <span className="caret-right"><FontAwesomeIcon icon="caret-right" /></span>
                                                            <span className="caret-down"><FontAwesomeIcon icon="caret-down" /></span>
                                                            {result3.key} ({result3.doc_count})
                                                        </a>

                                                        <ul className={props.showLvl3Children.indexOf(result3.key) === -1 ? "collapse" : "collapse show"} id={"level3bucket" + result3.key}>
                                                            {result3.group_by_level4Bucket && props.showLvl3Children.indexOf(result3.key) !== -1 &&
                                                                result3.group_by_level4Bucket.buckets.map((result4) =>
                                                                    <li key={result4.key} className="search-result--tree__circle-4">
                                                                        <a href={"#Iebucket" + result4.key}
                                                                            className={props.showLvl4Children.indexOf(result4.key) === -1 ? "collapsed" : ""}
                                                                            aria-expanded="false" aria-controls={result4.key.replace(/\s+/g, '')}
                                                                            onClick={() => props.handleLvl4Click(result4.key)}>

                                                                            <span className="circle"><FontAwesomeIcon icon="circle" /></span>
                                                                            <span className="caret-right"><FontAwesomeIcon icon="caret-right" /></span>
                                                                            <span className="caret-down"><FontAwesomeIcon icon="caret-down" /></span>
                                                                            {result4.key} ({result4.doc_count})
                                                                        </a>
                                                                        <ul className={props.showLvl4Children.indexOf(result4.key) === -1 ? "collapse" : "collapse show"} id={"#Iebucket" + result4.key}>
                                                                            {props.showLvl4Children.indexOf(result4.key) !== -1 &&
                                                                                <OeEntity selectedOrNot={props.selectedOrNot} showCircle="4" level="4" parentKey={result4.key} handleClick={props.handleClick}
                                                                                    handleOeEntityClick={props.handleOeEntityClick} handleTreeFieldsEvent={props.handleTreeFieldsEvent}
                                                                                    setShowOeChildren={props.setShowOeChildren} showOeChildren={props.showOeChildren} showLvl4Children={props.showLvl4Children}
                                                                                    showFields={props.showFields} resultKey={result.key} result2Key={result2.key} result3Key={result3.key} result4Key={result4.key}
                                                                                    level1Bucket={props.level1Bucket} level2Bucket={props.level2Bucket} level3Bucket={props.level3Bucket} level4Bucket={props.level4Bucket}
                                                                                />
                                                                            }
                                                                        </ul>
                                                                    </li>
                                                                )}
                                                        </ul>
                                                    </li>
                                                )}
                                        </ul>
                                    </li>
                                )}
                        </ul>
                    </li>
                )
                }
            </ul>
        )
    }
    return null;

}
export default BuildTree